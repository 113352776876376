import React from "react";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
    transition: "transform 0.2s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  cardMedia: {
    height: 0,
    paddingTop: "56.25%", // 16:9 ratio
  },
  cardContent: {
    flexGrow: 1,
  },
  beforeAfterContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "100%",
    height: "400px",
    margin: "auto",
    overflow: "hidden",
    borderRadius: "15px",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
  },
  beforeImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    transition: "width 0.5s ease",
  },
  afterImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    clipPath: "polygon(50% 0, 100% 0, 100% 100%, 50% 100%)",
    transition: "clip-path 0.5s ease",
  },
  slider: {
    position: "absolute",
    width: "100%",
    bottom: 10,
    left: 0,
    zIndex: 3,
    opacity: 0.7,
  },
  accordion: {
    marginTop: theme.spacing(2),
  },
}));

const Catalog = () => {
  const classes = useStyles();

  const beforeImage = "https://tscenter.com.co/build/assets/logo-jY5OX5EN.png";
  const afterImage = "https://tscenter.com.co/build/assets/logo-jY5OX5EN.png";

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography component="h1" variant="h4" color="primary" align="center" className={classes.header}>
        Catálogo de Productos
      </Typography>

      <Divider />

      <Grid container spacing={4} className={classes.cardGrid}>
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                className={classes.cardMedia}
                image={beforeImage}
                title="Producto 1"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  Producto 1
                </Typography>
                <Typography>
                  Descripción breve del producto. Incluye las principales características y beneficios.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        {/* Repite el Grid item para más productos */}

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography component="h2" variant="h5" color="textSecondary" align="center">
              Antes y Después del Servicio de Mantenimiento
            </Typography>
            <div className={classes.beforeAfterContainer}>
              <img src={beforeImage} alt="Before" className={classes.beforeImage} />
              <img src={afterImage} alt="After" className={classes.afterImage} />
              <input type="range" min="0" max="100" defaultValue="50" className={classes.slider} />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography component="h2" variant="h5" color="textSecondary">
              Pasos del Servicio
            </Typography>
            <Accordion className={classes.accordion}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Paso 1: Inspección Inicial</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Se realiza una inspección inicial del producto para evaluar su estado y determinar las necesidades de mantenimiento.
                </Typography>
              </AccordionDetails>
            </Accordion>
            {/* Añadir más pasos si es necesario */}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Catalog;
